import { render, staticRenderFns } from "./UserGallery.vue?vue&type=template&id=050b643c&scoped=true"
import script from "./UserGallery.vue?vue&type=script&lang=js"
export * from "./UserGallery.vue?vue&type=script&lang=js"
import style0 from "./UserGallery.vue?vue&type=style&index=0&id=050b643c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "050b643c",
  null
  
)

export default component.exports